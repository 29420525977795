'use strict';

angular.module('mvpcarrefourApp')
    .controller('RankingCtrl', ['$scope', 'Product', '_categories', '$window',
        function($scope, Product, _categories, $window) {

            Product.query(function(products) {

                // Estadisticas para los rankings
                let totalVotes = 0;
                let totalVotesCat = [];
                let totalVotesLifeStyle = { adult: 0, young: 0, family: 0 };

                // Confeccion de la lista de productos
                _.forEach(_categories, c => {
                    _.forEach(c.children, sc => {
                        _.forEach(products, function(p, key) {
                            if (sc._id == p.categories[0]._id) {
                                p.cat = c.slug;
                            }
                            // Parche Fix bug en produccion en ranking estilo de vida.
                            p.adult = p.votes.target['adult'];
                            p.family = p.votes.target['family'];
                            p.young = p.votes.target['young'];


                        })
                    });
                    totalVotesCat[c.slug] = 0;
                })

                var estadistica = [];

                _.forEach(products, function(p, key) {
                    // Acumulacion de voto al ranking total
                    totalVotes += p.votes.total;

                    // Acumulacion de voto a la categoria
                    totalVotesCat[p.cat] += p.votes.total;

                    // Acumulacion de voto a los estilos de vida
                    totalVotesLifeStyle['adult'] += p.votes.target["adult"]
                    totalVotesLifeStyle['young'] += p.votes.target["young"]
                    totalVotesLifeStyle['family'] += p.votes.target["family"]
                    estadistica[p.slug] = p.votes.total;

                })


                //Común a los 3 rankings:

                //Numero de elementos por paginacion segun dispositivo
                $scope.itemsPerPage = getItemsPerPage(products.length);
                angular.element($window).bind('resize', function() {
                    $scope.itemsPerPage = getItemsPerPage(products.length);
                    //$scope.$digest();
                });

                //Calculo de la posicion de cada elemento segun su ranking y filtro aplicado.
                $scope.getRankingGeneralPosition = function(p, r) {
                    r = _.reverse(_.sortBy(r, 'votes.total', 'slug'));
                    return r.indexOf(p) + 1;
                }

                //Calculo de numero de paginas segun ranking
                $scope.noOfPages = function(r) {
                    return (!angular.isUndefined(r)) ? Math.ceil(r.length / $scope.itemsPerPage) : 0;
                }


                //Ranking de productos
                //let orderList =  _.reverse(_.sortBy(products, ['votes.total', 'slug']));
                $scope.products = (angular.element($window).width() < 769) ?
                  _.reverse(_.sortBy(products, ['votes.total', 'slug'])).slice(0, 1) : products;
                $scope.currentPage1 = 1;
                $scope.page1 = 1;
                $scope.totalVotes = totalVotes;
                $scope.noOfPages1 = Math.ceil($scope.products.length / $scope.itemsPerPage);
                $scope.loadMore = function() {
                    if (angular.element($window).width() < 769) {
                        $scope.products = _.reverse(_.sortBy(products, ['votes.total', 'slug'])).slice(0, $scope.products.length + 1);
                    }
                };

                //Ranking por categorias
                $scope.byCategory = products;
                $scope.currentPage2 = 1;
                $scope.page2 = 1;
                //inicializacion de valores para categoria por defecto
                $scope.selected = _categories[0];
                $scope.totalVotesCat = totalVotesCat[_categories[0].slug];
                $scope.filterProductsCategory = function(cat) {
                    $scope.selected = cat;
                    $scope.totalVotesCat = totalVotesCat[cat.slug];
                    $scope.search = [];
                    console.log('search', $scope.search);
                    //console.log('categoria',cat);
                    //console.log('votos de la categoria', $scope.totalVotesCat);
                };
                $scope.search = [];
                $scope.filterProductsBySubCategory = function(subcategory) {

                    $scope.search = subcategory;
                    $scope.currentActive = subcategory;
                    $scope.option = _.startCase(_.replace(subcategory, /-/g, ' '));
                };

                $scope.filterFunction = function(element) {
                    return ($scope.search.length != 0) ? element.categories[0].slug == $scope.search : true;
                };

                //Ranking por Estilo de Vida
                $scope.byLifeStyle = products;
                $scope.currentPage3 = 1;
                $scope.page3 = 1;
                //inicializacion de valores para estilo de vida por defecto
                $scope.target = 'young';
                $scope.totalVotesLifeStyle = totalVotesLifeStyle['young'];
                // console.log('estilo de vida default jovenes:', $scope.totalVotesLifeStyle);
                $scope.filterProductsByLifeStyle = function(style) {
                    $scope.target = style;
                    $scope.totalVotesLifeStyle = totalVotesLifeStyle[style];
                    // console.log('estilo de vida:', style);
                    // console.log('total de votos por estilo de vida:', $scope.totalVotesLifeStyle);
                };



                // Parche Fix bug en produccion en ranking estilo de vida.
                $scope.getRankingGeneralPositionStyle = function(p, filtered3, target) {

                    filtered3 = target ? _.sortBy(filtered3, target) : filtered3;
                    return filtered3.indexOf(p) + 1;
                }

                //console.log('total de votos general:',  $scope.totalVotes);
                //console.log('total de votos por estilos de vida:', totalVotesLifeStyle);
                //console.log('votos de la categorias', totalVotesCat);
                //console.log('estadistica prod-votos',estadistica)
            });


            // Dropdown de Categorias
            $scope.categories = _categories;

            // Cambios de pagina
            $scope.pageChanged = function(r) {
                let pos = '';
                switch (r) {
                    case 1:
                        pos = $scope.page1;
                        break;
                    case 2:
                        pos = $scope.page2;
                        break;
                    case 3:
                        pos = $scope.page3;
                        break;
                }
                var startPos = (pos - 1) * 4;

            };

            function getItemsPerPage(max) {
                let w = angular.element($window).width();
                switch (true) {
                    case (w <= 768):
                        return max;

                    case (768 < w && w < 992):
                        return 2;

                    case (768 < w && w < 1200):
                        return 3;

                    case (1200 <= w):
                        return 4;

                }
            }

            // Mobile: Control de rankings visibles
            $scope.tab = 0;

            $scope.setTab = function(newTab) {
                $scope.tab = newTab;
            };

            $scope.isSet = function(tabNum) {
                let w = angular.element($window).width();
                if (w < 769) return $scope.tab === tabNum;
                else return true;
            };

        }
    ]).filter('voted', function() {
        return function(input, style) {

            let filteredLifeStyleSelected = [];
            let filteredLifeStyle = [];
            angular.forEach(input, function(item) {
                // Si hay estilo de vida seleccionado, se muestran productos correspondientes, sino se muestran
                // todos los productos que tengan votos en algun estilo de vida
                if (!angular.isUndefined(style) && item.votes.target['' + style + ''] > 0) {

                    filteredLifeStyleSelected.push(item);
                }

            });


            return filteredLifeStyleSelected.length > 0 ? filteredLifeStyleSelected : filteredLifeStyle
        };
    });
